// Column.js
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

function Column({ heading, text, subtitle, emphasis }) {
  return (
    <Box
      flex="1"
      mx="1rem"
      my="1rem"
      borderRadius="lg"
      borderWidth="2px"
      borderColor="orange.400"
      boxShadow="glow"
      bg="rgba(6, 16, 26, 0.7)"
      p="1.5rem"
    >
      <VStack align="stretch" height="100%" color="white">
        <Heading as="h2" size="md">
          {heading}
        </Heading>
        {subtitle && (
          <Text fontSize="sm" mt="-0.25rem">
            {subtitle}
          </Text>
        )}
        {text && <Text fontSize="sm">{text}</Text>}
        {emphasis && (
          <Text
            fontSize="md"
            fontWeight="bold"
            color="orange.400"
            mt="-0.25rem"
          >
            {emphasis}
          </Text>
        )}
      </VStack>
    </Box>
  );
}

export default Column;
