import React from 'react';
import { Box, Heading, VStack, Flex } from '@chakra-ui/react';
import { Copyright } from './Copyright';
import Links from './Links';
import FullPageLayout from './layout/FullPageLayout';

function Footer() {
  return (
    <FullPageLayout>
      <Box align="center" mb="10rem">
        <Heading as="h2" pb="2rem">
          join the community
        </Heading>
        <Links />
      </Box>

      <Flex
        bgImage="url('/assets/footer.png')"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="50% 50%"
        height="50vh"
        width="100%"
        direction="column"
        justify="flex-end"
      >
        <VStack as="footer" py="2rem">
          <Copyright />
        </VStack>
      </Flex>
    </FullPageLayout>
  );
}

export default Footer;
