// Part8.js
import React from 'react';
import { Box } from '@chakra-ui/react';
import FullPageLayout from '../../layout/FullPageLayout';

function Part8() {
  return (
    <FullPageLayout>
      <Box
        bgImage="url('assets/image8.png')"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
        // height="50vh"
        height={{ base: '30vh', sm: '50vh' }}
        width="100%"
      />
    </FullPageLayout>
  );
}

export default Part8;
