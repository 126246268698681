import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Flex,
  Image,
  Text,
  Spacer,
} from '@chakra-ui/react';
import DefaultLayout from '../../layout/DefaultLayout';

const faqs = [
  {
    question: 'What is Tokenaut?',
    answer:
      'Tokenaut is a revolutionary launchpad platform for memecoins and crypto projects. It’s designed to foster collaboration and leverage advanced AI technology to predict the success of potential projects, ensuring only the most promising ones make the cut.',
  },
  {
    question: 'Why Tokenaut?',
    answer:
      'Tokenaut stands out with its collaborative ethos and innovative technology. It offers a space for project creators and investors to come together, ensuring that promising projects get the support they need to flourish. Moreover, the platform’s AI capabilities help assess the viability of projects, adding an additional layer of security for investors.',
  },
  {
    question: 'What is the $TNT token utility?',
    answer:
      'The $TNT token is at the heart of the Tokenaut ecosystem. It can be staked to earn $TNT2 tokens and to gain access to platform features. Additionally, it is used for participation in project launches and rewards distribution. The $TNT2 tokens earned can also be staked for additional rewards.',
  },
  {
    question: 'When will I get my $TNT tokens?',
    answer:
      'You can claim your $TNT tokens soon after the completion of the presale period, according to the terms specified in the presale.',
  },
  {
    question: 'When can I start trading $TNT?',
    answer:
      'The trading of $TNT tokens will commence shortly after the presale ends and the tokens are distributed to the participants. The exact date will be announced through official Tokenaut channels.',
  },
  {
    question: 'When will I be able to participate in project launches?',
    answer:
      'As soon as you have your $TNT tokens, you can start participating in project launches hosted on the Tokenaut platform.',
  },
  {
    question: 'What are the benefits of staking $TNT?',
    answer:
      'Staking $TNT tokens enables you to earn $TNT2 tokens. It also provides access to various platform features and participation in project launches. Over time, staking contributes to platform stability and offers opportunities for continuous engagement within the Tokenaut ecosystem.',
  },
  // Add more FAQs here...
];

function FAQ() {
  return (
    <DefaultLayout>
      <Flex
        width="100%"
        direction={{ base: 'column', md: 'row' }}
        align="center"
      >
        <Box
          flex={{ base: '0 0 100%', md: '0 0 58%' }}
          pr={{ base: '0', md: '2rem' }}
        >
          <Heading mb={4}>Frequently Asked Questions</Heading>
          <Accordion defaultIndex={[0]} borderColor="transparent">
            {faqs.map((faq, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontSize="sm">{faq.question}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel bg="pink.800">
                  <Text fontSize="">{faq.answer}</Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
        <Spacer />

        <Box width={{ base: '100%', md: '45%' }}>
          <Image src="./assets/faq-3.jpg" alt="" w="100%" />
        </Box>
      </Flex>
    </DefaultLayout>
  );
}

export default FAQ;
