import React from 'react';
import {
  Flex,
  Heading,
  Text,
  Box,
  UnorderedList,
  ListItem,
  Image,
  Container,
  Spacer,
} from '@chakra-ui/react';
import DefaultLayout from '../../layout/DefaultLayout';

function Part2() {
  return (
    <DefaultLayout pt="5rem">
      {/* <Flex align="stretch" direction={{ base: 'column', md: 'row' }}>
          <Box
            height="50vh"
            bgImage="url('assets/image2.png')"
            bgSize="cover"
            bgPosition="50% 100%"
            flex="1"
          />
          <Flex width={{ base: '100%', md: '60%' }}> */}
      {/* <VStack
        spacing={4}
        justify="center"
        height="100%"
        color="white"
        ml={{ base: 0, md: '2rem' }}
      > */}

      {/* </VStack> */}
      {/* </Flex>
        </Flex> */}
      <Container maxW="3xl">
        <Flex direction={{ base: 'column', md: 'row' }} align="top">
          <Box textAlign="left" w={{ base: '100%', md: '35%' }}>
            <Heading as="h2" size="lg">
              The Platform
            </Heading>
            <Image pt="1rem" src="assets/image2.png"></Image>
          </Box>
          <Spacer />
          <Box w={{ base: '100%', md: '60%' }}>
            <Text>
              Revolutionising the crypto scene with a groundbreaking
              collaborative platform for projects.
            </Text>
            <UnorderedList pt="1rem">
              <ListItem>Two native currencies: $TNT and $TNT2</ListItem>
              <ListItem>
                Dynamic and interactive method to expedite the debut of
                memecoins and projects.
              </ListItem>
              <ListItem>
                Holders can stake and farm additional $TNT2 rewards.
              </ListItem>
              <ListItem>
                Substantial 10% of all $TNT tokens earmarked for liquidity
                supply
              </ListItem>
              <ListItem>
                To foster close collaboration with platform-launched projects;
                $TNT2 is secured for four-weeks post-launch.
              </ListItem>
            </UnorderedList>
          </Box>
        </Flex>
        <Text fontWeight="bold" pt="2rem" fontSize="xl" align="center">
          Tokenaut pioneers a high-quality, continuously evolving platform for
          launching and supporting memecoins and projects.
        </Text>
      </Container>
    </DefaultLayout>
  );
}

export default Part2;
