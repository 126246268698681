import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';

function Links() {
  const links = [
    // { label: 'Discord', href: 'https://google.com' },
    { label: 'Telegram', href: 'https://t.me/tokenautai' },
    { label: 'Twitter', href: 'https://twitter.com/TokenautAI' },
  ];

  return (
    <Box>
      {links.map((link, index) => (
        <React.Fragment key={link.label}>
          {index > 0 && (
            <Text as="span" mx={2}>
              |
            </Text>
          )}
          <Link
            fontSize="sm"
            href={link.href}
            rel="nofollow noreferrer"
            target="_blank"
          >
            {link.label}
          </Link>
        </React.Fragment>
      ))}
    </Box>
  );
}

export default Links;
