import React from 'react';
import {
  Button,
  Container,
  FormControl,
  Link,
  Input,
  Text,
  Heading,
  Box,
  Image,
  Flex,
  Spacer,
  HStack,
  Icon,
} from '@chakra-ui/react';
// import {
//   ConversionArrowDown,
//   PresaleProgressBar,
//   PresaleRates,
//   HeroVStack,
//   PurchaseInputField,
//   CustomSelect,
// } from './CustomComponents';
import FullPageLayout from '../../layout/FullPageLayout';
// import CountdownTimer from './CountdownTimer';
import '../../global.css';
// import Links from '../../Links';
import logo from '../../../logo.svg';
import { ArrowRightIcon } from '@chakra-ui/icons';

function Hero() {
  return (
    <FullPageLayout
      bgImage="assets/hero.png"
      height="100vh"
      pt={{ base: '0', md: '0' }}
    >
      <Flex h="100%" align="center" justify="center" mx="2rem">
        <Container
          maxWidth="xl"
          bg="rgba(0,0,0,0.75)"
          borderRadius="0.5rem"
          pt="1.5rem"
          pb="1.25rem"
          px="1rem"
        >
          <Flex align="center">
            <Box w="25%">
              <Image src="assets/image1.png" alt="Logo" borderRadius="full" />
            </Box>
            <Spacer />
            <Flex w="73%" justify="end" align="">
              <Box w="90%">
                {/* <CountdownTimer /> */}
                <Image
                  w="70%"
                  src={logo}
                  className="App-logo"
                  alt="logo"
                  pb="1em"
                />
                <Heading as="h2" fontSize={{ base: 'md', sm: '2xl' }}>
                  The Ultimate Launchpad for Memecoins and Crypto Projects
                </Heading>
              </Box>
            </Flex>
          </Flex>
          <Flex justify="center" pt="1rem">
            <Box w="97%">
              <Text align="justify">
                The transformative platform for Memecoins and Crypto project the
                world has been waiting for.
              </Text>

              <Flex
                direction={{ base: 'column', sm: 'row' }}
                align="center"
                justify="space-between"
                pt="1rem"
              >
                <Heading size="md">
                  Join the community
                  <ArrowRightIcon ml="0.5rem" boxSize="0.75rem" />
                </Heading>
                <Flex
                  direction="row"
                  pt={{ base: '1rem', sm: '0' }}
                  gap="0.5rem"
                >
                  <Button
                    as="a"
                    href="https://t.me/tokenautai"
                    size="sm"
                    target="_blank"
                  >
                    <Box boxSize="20px" mr="5px">
                      <Image src="/assets/telegram.png" />
                    </Box>
                    Telegram
                  </Button>
                  <Button
                    as="a"
                    href="https://twitter.com/TokenautAI"
                    size="sm"
                    target="_blank"
                  >
                    <Box boxSize="20px" mr="5px">
                      <Image src="/assets/twitter.png" />
                    </Box>
                    Twitter
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Flex>
    </FullPageLayout>
  );
}

export default Hero;
