import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Home from './components/pages/Home';
// import Whitepaper from './components/pages/Whitepaper';
import NoMatch from './components/pages/NoMatch';
import ScrollToTop from './components/ScrollToTop';
import theme from './theme';
import Blog from './components/pages/Blog';
import BlogPost from './components/pages/news/BlogPost';
import Rewards from './components/pages/Rewards';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <ChakraProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<Blog />} />
            <Route path="/news/:slug" element={<BlogPost />} />
            {/* <Route path="/rewards" element={<Rewards />} /> */}
            <Route path="/rewards" element={<Rewards />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <Footer />
        </Router>
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
