import React from 'react';
import { Box, Heading, Text, VStack, Flex } from '@chakra-ui/react';
import DefaultLayout from '../../layout/DefaultLayout';
import Column from '../../layout/Column';
import ColumnWrapper from '../../layout/ColumnWrapper';

function HowToBuy() {
  const heading1 = 'erc-20';
  const subtitle1 = 'ethereum network';
  const emphasis1 = 'eth, usdt';
  const heading2 = 'bep-20';
  const subtitle2 = 'binance smart chain';
  const emphasis2 = 'bnb, usdt.';

  return (
    <DefaultLayout>
      {/* <Flex align="center" direction={{ base: 'column', md: 'row' }}> */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        minH={{ base: '40rem', md: '70vh' }}
      >
        <Box
          bgImage="url('assets/image5.png')"
          bgSize="cover"
          bgPosition="50% 50%"
          flex="1"
        />
        <Box
          width={{ base: '100%', md: '50%' }}
          p={{ base: '2rem 0 0', md: '0 0 0 2rem' }}
        >
          <VStack
            spacing={4}
            align="left"
            justify="center"
            height="100%"
            color="white"
          >
            <Heading as="h2">Getting $TNT</Heading>
            <Text>
              $TNT: the golden ticket to be part of a groundbreaking new
              blockchain project that is set to redefine the industry norms.
            </Text>
            <Text>
              Embark on the journey to become a part of the vibrant Tokenaut
              ecosystem.
            </Text>
            <ColumnWrapper>
              <Column
                heading={heading1}
                subtitle={subtitle1}
                emphasis={emphasis1}
              />
              <Column
                heading={heading2}
                subtitle={subtitle2}
                emphasis={emphasis2}
              />
            </ColumnWrapper>
          </VStack>
        </Box>
      </Flex>
    </DefaultLayout>
  );
}

export default HowToBuy;
