import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config: config,
  breakpoints: {
    xs: '20em', // 320px mobile devices
    sm: '30em', // 480px ipads + tablets
    md: '48em', // 768px laptops + small screens
    lg: '62em', // 992px laptops
    // xl: '80em', // 1280px
    // '2xl': '96em', // 1536px
  },
  shadows: {
    glow: '0 0 20px 0 rgba(244,112,19, 0.35)',
  },
  styles: {
    global: {
      body: {
        // fontSize: '18px',
        // bg: '#09111a',
        bg: '#00030F',
      },
    },
  },
  colors: {
    brand: {
      100: '#f7fafc',
      900: '#1a202c',
    },
  },
  fonts: {
    heading: 'Sora',
    body: 'Sora',
  },
  fontWeights: {
    heading: 600,
    body: 300,
  },
  components: {
    Input: {
      baseStyle: {
        _focus: {
          borderColor: 'green', // change to your preferred color
          boxShadow: 'none',
        },
      },
    },
    Collapse: {
      baseStyle: {
        content: {
          bg: 'gray.800',
          color: 'white',
        },
      },
    },
    Link: {
      baseStyle: {
        textDecoration: 'none',
      },
      _hover: {
        textDecoration: 'none',
      },
    },
    Button: {
      baseStyle: {
        color: 'white',
      },
    },
  },
});

export default theme;
