import React from 'react';
import {
  Container,
  Heading,
  UnorderedList,
  ListItem,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import DefaultLayout from '../../layout/DefaultLayout';

function Roadmap() {
  return (
    <DefaultLayout>
      <Container maxW="2xl">
        <Heading textAlign="center" pb="2rem">
          Roadmap
        </Heading>
        <Grid
          columns={{ base: 1, md: 2 }}
          gap={6}
          w="100%"
          margin={'auto'}
          templateColumns="repeat(2, 1fr)"
        >
          <GridItem colSpan={{ base: '2', md: '1' }}>
            <Heading as="h3" fontSize="md" pb="1rem">
              Q2 2023 — Phase 1
            </Heading>
            <UnorderedList>
              <ListItem>Core architecture of the platform.</ListItem>
              <ListItem>
                Establish AI-driven algorithms to assess projects.
              </ListItem>
              <ListItem>Staking mechanisms for $TNT.</ListItem>
            </UnorderedList>
          </GridItem>
          <GridItem colSpan={{ base: '2', md: '1' }}>
            <Heading as="h3" fontSize="md" pb="1rem">
              Q3 2023 — Phase 2
            </Heading>
            <UnorderedList>
              <ListItem>Beta testing with a select group of users</ListItem>
              <ListItem>$TNT token presale</ListItem>
              <ListItem>$TNT Public token launch</ListItem>
            </UnorderedList>
          </GridItem>
          {/* <GridItem colSpan={2}> */}
          <GridItem colSpan={{ base: '2', md: '1' }}>
            <Heading as="h3" fontSize="md" pb="1rem">
              Q4 2023 — Phase 3
            </Heading>
            <UnorderedList>
              <ListItem>Official platform launch</ListItem>
              <ListItem>
                First batch of projects with AI algorithms in action
              </ListItem>
              <ListItem>
                Curated projects from proprietary AI assessment
              </ListItem>
            </UnorderedList>
          </GridItem>
          {/* <GridItem colSpan={2}> */}
          <GridItem colSpan={{ base: '2', md: '1' }}>
            <Heading as="h3" fontSize="md" pb="1rem">
              Q2 2023 — Phase 4
            </Heading>
            <UnorderedList>
              <ListItem>Expansion and improvement</ListItem>
              <ListItem>Introducing new features to platform</ListItem>
              <ListItem>Continuous refinement of AI algorithms</ListItem>
              <ListItem>Establish crypto-space partnerships pillars</ListItem>
            </UnorderedList>
          </GridItem>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}

export default Roadmap;
