import React from 'react';
import SEO from '../../SEO';
import {
  Card,
  Heading,
  Text,
  HStack,
  Container,
  VStack,
  CardBody,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { posts } from './posts';
import DefaultLayout from '../../layout/DefaultLayout';
import { CalendarIcon, StarIcon } from '@chakra-ui/icons';

function BlogPost() {
  const { slug } = useParams();
  const post = posts.find((post) => post.slug === slug);

  if (!post) return <Text>Post not found.</Text>;

  return (
    <>
      <SEO
        title={'Tokenaut—' + post.title}
        description={post.excerpt}
        type="article"
      />
      {/* <Helmet>
        <title>{post.title + ' - : Tokenaut'}</title>
        <meta property="og:title" content={post.title + ' - : Tokenaut'} />
        <meta
          property="og:image"
          content="https://tokenaut.ai/assets/opengraph.jpg"
        />
        <meta property="og:description" content={post.excerpt} />
      </Helmet> */}
      <DefaultLayout>
        <Container>
          <Card bgGradient="linear(to-tr, purple.700, pink.700)">
            <CardBody>
              <Heading>{post.title}</Heading>
              <HStack pb="1rem" gap="5">
                <HStack>
                  <CalendarIcon />
                  <Text>{post.date}</Text>
                </HStack>
                <HStack>
                  <StarIcon />
                  <Text>{post.author}</Text>
                </HStack>
              </HStack>
              <VStack spacing="3" align="left">
                {post.content()}
              </VStack>
            </CardBody>
          </Card>
        </Container>
      </DefaultLayout>
    </>
  );
}

export default BlogPost;
