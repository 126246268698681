import { Box, Text, Image, Link, VStack, Flex, HStack } from '@chakra-ui/react';

export const posts = [
  {
    date: '05 june 2023',
    slug: 'toby-cosmic-quest',
    author: 'toady',
    title: "Toby's Cosmic Quest",
    excerpt:
      "Join toby the Toad on an interstellar mission as he leaps across galaxies to uncover the trendiest memecoins and crypto projects. Trust in toby's diligence and courage as he navigates the vast expanse of the blockchain universe.",
    content: () => (
      <>
        <Text>
          toby the Toad leaps, to the vast cosmic scape,
          <br />
          In search of coins, where digital shadows drape.
          <br />
          Trust his keen sight, as through galaxies he'll sweep,
          <br />
          To find trending projects, in the crypto's deep.
        </Text>

        <Text>
          Beneath the glow of distant stars, he'll roam,
          <br />
          Hunting for memecoins, far from earthly home.
          <br />
          Riding comets, through the blockchain's night,
          <br />
          toby's space escapade, a pioneering flight.
        </Text>

        <Text>
          From nebula to nebula, trust him to explore,
          <br />
          The next big crypto project, he will surely score.
          <br />
          toby the Toad, on this celestial quest,
          <br />
          In his diligence and courage, we invest.
        </Text>
      </>
    ),
  },
  {
    date: '07 june 2023',
    slug: 'journey-vision',
    author: 'toady',
    title:
      'Our Path Towards a Digital Horizon: The Journey and Vision of Our Blockchain Project',
    excerpt:
      'Born from a blend of innovative technology and human spirit, Tokenaut is poised to usher in this new era of change. Information is power, and our aim is to ensure that power is in your hands.',
    // datePublished: '2023-05-01',
    content: () => (
      <>
        <Image src="/assets/image8.png" />
        <Text>
          As the world finds itself spinning on the axis of digital
          transformation, the intricate world of blockchain technologies and
          cryptocurrencies has emerged, abundant with promise and potential. Our
          project, born from a blend of innovative technology and human spirit,
          is poised to usher in this new era of change.
        </Text>
        <Text>
          Information is power, and our aim is to ensure that power is in your
          hands. Be the pioneer, discovering the most exciting and promising
          memecoins and crypto projects. With a blend of thorough research and
          data-backed insights, we empower you to traverse the complex landscape
          of crypto with precision and assuredness.
        </Text>
        <Text>
          The cornerstone of our project is a modern marvel - a predictive AI
          algorithm, capable of conducting deep analysis of the crypto market.
          This constant learner is engineered to unveil unseen patterns, deliver
          unmatched market predictions, and equip you with accurate investment
          foresights. Our ambition is to transform your uncertainty into a
          well-defined strategy, turning potential risks into measurable
          rewards.
        </Text>
        <Text>
          We are more than a technology. We are a collective, thriving on shared
          wisdom and united purpose. We stand tall as a community-centric
          platform, inviting you to engage, interact, and grow alongside fellow
          crypto enthusiasts. Every question posed, every insight contributed,
          fuels our collective wisdom, fostering a culture of shared learning
          and mutual growth.
        </Text>
        <Text>
          We extend an invitation to join us as we stride into this promising
          new era. An era where technological innovation marries community
          collaboration, where potential sparks into possibility. Step into the
          dawn of the digital renaissance, your passage to the future of
          cryptocurrency. The world of tomorrow awaits, just a click away.
        </Text>
        <Text>
          Our journey may be in its early stages, and the path ahead may not
          always be smooth, but we stride forward with humility, shared purpose,
          and an unwavering belief in our vision. Our commitment remains to
          build a platform, a community, a revolution, that is relatable,
          inclusive, and powerful. Together, we will shape the future of
          cryptocurrency, one block at a time.
        </Text>
      </>
    ),
  },
  {
    date: '11 june 2023',
    slug: 'codex-unveiled-gitbook',
    author: 'toady',
    title: 'Inside Our Vision: The Launch of Our GitBook Whitepaper',
    excerpt:
      'Dive into our GitBook Whitepaper for a transparent look at our journey in the world of cryptocurrency. Discover our tokenomics, roadmap, and shared vision as we shape the dawn of the digital renaissance together.',
    content: () => (
      <>
        <Text>The Codex to Our Digital Odyssey Unveiled.</Text>
        <Text>
          As we navigate the dawn of the digital renaissance, it is vital to
          keep our compass aligned, our vision clear. To that end, we are
          thrilled to introduce our comprehensive Whitepaper, now available on
          GitBook. This document serves as a detailed map, charting our journey
          into the evolving landscape of cryptocurrencies and blockchain
          technology.
        </Text>

        <Text>
          Our Whitepaper lays bare the intricacies of our platform – from our
          core tokenomics, underpinning the economic model of our project, to
          our roadmap, painting the trajectory of our progress and future plans.
          This is a testament to our commitment to transparency and our belief
          in engaging the community every step of the way.
        </Text>

        <Text>
          So, dive deep into the heart of our project. Explore our tokenomics,
          understanding the driving economic forces of our platform. Ponder our
          roadmap, visualizing the future we aim to shape together.
        </Text>
        <Text>
          Join us in this thrilling journey. Engage, question, and grow with us.
          Our Whitepaper on GitBook is not just a guide but a living document,
          charting our collective journey towards the future of cryptocurrency.
          Together, we shape the dawn of the digital renaissance.
        </Text>
      </>
    ),
  },
  {
    date: '15 june 2023',
    slug: 'pledge-to-early-supporters',
    author: 'toady',
    title: 'Recognition and Reward: Our Pledge to Early Supporters',
    excerpt:
      'The strength of a project lies in the belief of its early supporters. In recognition of this trust, our commitment remains firm - to ensure our journey of growth and success is a shared one. With rewarding initiatives on the horizon, we stay true to our principle of reciprocity, recognizing and rewarding our early supporters. ',
    content: () => (
      <>
        <Text as="cite">Unveiling the Blueprint of Mutual Success.</Text>
        <Text>
          At the heart of our project's journey, lie our early supporters. Those
          courageous souls who believed in the potential of our vision, even
          when it was just a seedling. Their faith fuels our commitment, their
          support propels our ambition. This gratitude has solidified into a
          central tenet of our platform – the firm belief in rewarding those who
          stood by us in our nascent stages.
        </Text>
        <Flex align="center" justify="center">
          <Image src="/assets/image4.jpg" borderRadius="1rem" boxSize="50%" />
        </Flex>
        <Text>
          Every breakthrough technology or revolutionary idea stands tall on the
          shoulders of early supporters. They are the explorers who chart the
          course of the unexplored, the pioneers who dare to dream. Our project,
          rooted in the world of blockchain technologies and cryptocurrencies,
          is no different. It is you, our early supporters, who have enabled us
          to venture into this realm with confidence and conviction.
        </Text>
        <Text>
          Therefore, we believe it is not just important, but instrumental, to
          acknowledge and reward our early supporters. This principle of
          reciprocity is what drives us to ensure that our growth is not a
          solitary journey, but one we embark on together.
        </Text>
        <Text>
          And so, in the spirit of gratitude and shared success, we are excited
          to share that we have some significant plans in the pipeline,
          specially tailored to reward our early supporters. As we continue to
          evolve and make strides in the world of cryptocurrencies, our early
          supporters can look forward to being a part of this shared prosperity.
        </Text>
        <Text>
          In our vision for the future, every question you've asked, every
          insight you've shared, and every step you've taken with us adds to
          your stake in this journey. Our commitment to recognizing these
          contributions underpins the exciting reward initiatives we have on the
          horizon.
        </Text>
        <Text>
          We believe that the dawn of the digital renaissance is a shared
          horizon, a collective success story. Together, we are not just
          building a platform; we are shaping the future of cryptocurrency.
        </Text>
      </>
    ),
  },
];
// {
//   date: 'july 2023',
//   slug: 'second-post',
//   author: 'toady',
//   title: 'Second Post',
//   excerpt: 'This is the excerpt for the second post.',
//   content: () => (
//     <Box>
//       <Text>This is the full text for the second post.</Text>
//       <Image src="/assets/twitter.png" />
//       <Link href="https://www.example3.com" isExternal>
//         Example link 3
//       </Link>
//       <Link href="https://www.example4.com" isExternal>
//         Example link 4
//       </Link>
//     </Box>
//   ),
// },
