import React from 'react';
import Hero from './home/Hero';
// import Part1 from './home/Part1';
import Part2 from './home/Part2';
import Part3 from './home/Part3';
import Part4 from './home/Part4';
import HowToBuy from './home/HowToBuy';
import Roadmap from './home/Roadmap';
import Part7 from './home/Part7';
import Part8 from './home/Part8';
import FAQ from './home/FAQ';
import SEO from '../SEO';
// import Footer from '../Footer';

const Home = () => {
  return (
    <>
      <SEO
        title="Tokenaut — The Ultimate Launchpad for Memecoins and Crypto Projects"
        description="The transformative platform for Memecoins and Crypto project the world has been waiting for."
        type="website"
      />

      <Hero />
      {/* <Part1 /> */}
      <Part2 />
      <Part3 />
      <Part4 />
      <HowToBuy />
      <Roadmap />
      <Part7 />
      <Part8 />
      <FAQ />
      {/* <Footer /> */}
    </>
  );
};

export default Home;
