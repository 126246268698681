import React from 'react';
import { Container, Heading, Text } from '@chakra-ui/react';
import DefaultLayout from '../../layout/DefaultLayout';
import ColumnWrapper from '../../layout/ColumnWrapper';
import Column from '../../layout/Column';

function Part6() {
  const heading1 = 'Purchase $TNT In Presale';
  const text1 =
    'Become a part of the Tokenaut community and acquire $TNT tokens through the presale widget.';
  const heading2 = 'Share Your Referral Code';
  const text2 =
    'Post-purchase, copy your exclusive referral code and spread the word.';
  const heading3 = 'Earn $TNT Airdrop';
  const text3 =
    'You’ll earn a portion of 100 billion $TNT, proportionate to the total amount raised via your referral link.';

  return (
    <DefaultLayout>
      <Container maxW="2xl">
        <Heading textAlign="center" pb="2rem">
          $TNT 100 billion Token Airdrop
        </Heading>
        <Text>
          Tokenaut rewards its early supporters (presale) with substantial $TNT
          airdrop! Rewards are based on total referral amount. To join, just
          follow these easy steps:
        </Text>
      </Container>

      <ColumnWrapper pt="2rem" directionProp={{ base: 'column', md: 'row' }}>
        <Column heading={heading1} text={text1} />
        <Column heading={heading2} text={text2} />
        <Column heading={heading3} text={text3} />
      </ColumnWrapper>
    </DefaultLayout>
  );
}

export default Part6;
