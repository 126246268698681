import React from 'react';
import DefaultLayout from '../layout/DefaultLayout';
import {
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Container,
} from '@chakra-ui/react';
import SEO from '../SEO';

const Rewards = () => {
  return (
    <DefaultLayout>
      <SEO
        title="Submission received."
        description="Thank you for reaching out! We have successfully received your
          submission."
      />
      <Container maxW="lg">
        <Heading>Submission Successfully Received</Heading>
        <Text>
          Thank you for reaching out! We have successfully received your
          submission. <br />
          <br />
          Rest assured, we are reviewing your submission and will get back to
          you as soon as possible. We appreciate your patience and your interest
          in our platform. While waiting, feel free to explore more about us
          through our blogs and community forums. Thank you again for your
          engagement. We're looking forward to connecting with you soon!
        </Text>
      </Container>
    </DefaultLayout>
  );
};

export default Rewards;
