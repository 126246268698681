import React from 'react';
import { Box, Container, useBreakpointValue } from '@chakra-ui/react';

function DefaultLayout({ bgImage, children, height = 'auto', pt }) {
  const defaultPt = useBreakpointValue({
    base: '5rem',
    sm: '7rem',
    md: '10rem',
  });
  const paddingTop = pt || defaultPt;

  return (
    <Box
      zIndex="banner"
      top="0"
      left="0"
      height={height}
      bgImage={`url(${bgImage})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      pt={paddingTop}
      px="0.5rem"
    >
      <Container maxW="65rem">{children}</Container>
    </Box>
  );
}

export default DefaultLayout;
