// Part3.js
import React from 'react';
import { Flex, Box, Container } from '@chakra-ui/react';
import FullPageLayout from '../../layout/FullPageLayout';
import Column from '../../layout/Column';
import ColumnWrapper from '../../layout/ColumnWrapper';

function Part3() {
  const heading1 = 'Revolutionary';
  const text1 =
    'Pioneering AI-Driven Project Analysis in the Dynamic World of Cryptocurrency.';
  const heading2 = 'Crypto Insights';
  const text2 =
    'Empowering Users with AI-Based Analysis Tools for Informed Decision Making in the Ever-Changing Crypto Landscape.';
  const heading3 = 'AI + Blockchain';
  const text3 =
    'Harnessing the Power of AI to Navigate and Thrive in the Volatile Yet Exciting World of Memecoins and Crypto Projects.';

  return (
    <FullPageLayout>
      <Box
        bgImage="url('assets/image3.png')"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
        height="50vh"
        width="100%"
      />
      <Container maxW="80%">
        <Flex
          align="center"
          direction={{ base: 'column', lg: 'row' }}
          h="100%"
          mt="-3rem"
        >
          <ColumnWrapper directionProp={{ base: 'column', lg: 'row' }}>
            <Column heading={heading1} text={text1} />
            <Column heading={heading2} text={text2} />
            <Column heading={heading3} text={text3} />
          </ColumnWrapper>
        </Flex>
      </Container>
    </FullPageLayout>
  );
}

export default Part3;
