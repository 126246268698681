import React from 'react';
import { Box } from '@chakra-ui/react';

function FullPageLayout({
  bgImage,
  children,
  height = 'auto',
  pr = '0',
  pl = '0',
  pt = '10rem',
}) {
  return (
    <Box
      zIndex="banner"
      top="0"
      left="0"
      height={height}
      bgImage={`url(${bgImage})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      margin="0 auto"
      pt={pt}
      pr={pr} // paddingRight
      pl={pl} // paddingLeft
    >
      {children}
    </Box>
  );
}

export default FullPageLayout;
