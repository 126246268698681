import { Heading } from '@chakra-ui/react';
import React from 'react';
import DefaultLayout from '../layout/DefaultLayout';

const NoMatch = () => {
  return (
    <DefaultLayout>
      <Heading>Page not found</Heading>
      <p>Page has either moved or never existed.</p>
    </DefaultLayout>
  );
};

export default NoMatch;
