import React from 'react';
import { Flex, Heading, Text, VStack, Box } from '@chakra-ui/react';
import DefaultLayout from '../../layout/DefaultLayout';

function Part2() {
  return (
    <DefaultLayout pr="3rem">
      <Flex
        direction={{ base: 'column-reverse', md: 'row' }}
        minH={{ base: '40rem', md: '50vh' }}
      >
        <VStack
          w={{ base: '100%', md: '60%' }}
          spacing={4}
          align="left"
          justify="center"
          color="white"
          p={{ base: '2rem 0 0', md: '0 2rem 0 0' }}
        >
          <Heading as="h2" size="lg">
            $TNT - "Fueling the Future of Cryptocurrency Projects"
          </Heading>
          <Text>
            The XXX ecosystem invites users to stake $XXX tokens, yielding tier
            2 $XXX2 tokens and unlocking exclusive platform features. This
            mechanism fosters enduring engagement and ensures the platform's
            sustained success.
          </Text>
          {/* <Text>
            The Tokenaut dashboard is a dynamic space where users can view and share details about launched projects. With filters and sorting options, users can browse projects based on criteria such as popularity, recency, or specific themes. We offer an innovative project launch solution, leveraging advanced AI technology, user-friendly analytics, and a
            A token-based reward system to revolutionize project launches,
            cultivating a thriving crypto community.
          </Text> */}
        </VStack>
        <Box
          bgImage="url('assets/image4.png')"
          bgSize="cover"
          bgPosition="50% 50%"
          flex="1"
        />
      </Flex>
    </DefaultLayout>
  );
}

export default Part2;
