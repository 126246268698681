import React from 'react';
import { Flex } from '@chakra-ui/react';

const ColumnWrapper = ({ children, directionProp, ...props }) => {
  return (
    <Flex
      align="stretch"
      direction={directionProp}
      {...props}
      height="min-content"
      mx="auto"
    >
      {children}
    </Flex>
  );
};

export default ColumnWrapper;
