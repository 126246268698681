import React from 'react';
import SEO from '../SEO';
import {
  SimpleGrid,
  Heading,
  Text,
  GridItem,
  Card,
  CardBody,
  Stack,
  CardFooter,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { posts } from './news/posts';
import DefaultLayout from '../layout/DefaultLayout';

function Blog() {
  const bgGradient = useColorModeValue(
    'linear(to-tr, purple.700, pink.700)',
    'linear(to-tr, purple.700, pink.700)'
  );

  return (
    <>
      <SEO
        title="Tokenaut Blog"
        description="The Tokenaut news where you'll find the latest updates, insightful articles and more."
        type="blog"
      />
      {/* <Helmet>
        <title>Tokenaut Blog</title>
        <meta property="og:title" content="Tokenaut Blog" />
        <meta
          property="og:image"
          content="https://tokenaut.ai/assets/opengraph.jpg"
        />
        <meta
          property="og:description"
          content="The transformative platform for Memecoins and Crypto project the
                world has been waiting for."
        />
      </Helmet> */}
      <DefaultLayout>
        <Heading>News</Heading>
        <Text pb="1rem">
          Here, you'll find the latest updates, insightful articles, and our
          shared journey towards the digital renaissance. Stay informed, stay
          engaged.
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          {posts.map((post, index) => (
            <GridItem key={index} style={{ order: posts.length - index }}>
              <Link to={`/news/${post.slug}`}>
                <Card bgGradient={bgGradient}>
                  <CardBody>
                    <Stack spacing="3">
                      <Heading size="md">{post.title}</Heading>
                      <Text>{post.excerpt}</Text>
                    </Stack>
                  </CardBody>
                  <CardFooter>
                    <Text fontSize="xs" id="publish-date">
                      {post.date}
                    </Text>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
          ))}
        </SimpleGrid>
      </DefaultLayout>
    </>
  );
}

export default Blog;
