import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Text,
  IconButton,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as ELink } from '@chakra-ui/react';
import logo from '../logo.svg';

const MenuItems = ({ children, to = '/', target }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    <Link to={to} target={target}>
      {children}
    </Link>
  </Text>
);

const Navigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex align="top">
      <Flex
        py="1rem"
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        paddingX="1.5rem"
        bg={{ base: 'rgba(0,0,0,0.6)', sm: 'transparent' }}
        backdropFilter="blur(3px)"
        color="white"
        position="fixed"
        width="100%"
        top="0"
        zIndex="docked"
      >
        <Box align="center" width="150px">
          <Link to="/">
            <Image src={logo} className="App-logo" alt="logo" />
          </Link>
        </Box>

        <Box
          display={{ base: 'block', md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        >
          <IconButton
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            variant="outline"
            aria-label="Open Menu"
          />
        </Box>

        <Box
          display={{ base: isOpen ? 'block' : 'none', md: 'flex' }}
          width={{ base: 'full', md: 'auto' }}
          alignItems="center"
          flexGrow={1}
        >
          <ELink
            as={IconButton}
            bg="transparent"
            onClick={() => window.open('https://t.me/tokenautai', '_blank')}
            target="_blank"
            rel="noopener noreferrer"
            display={{ base: 'none', md: 'flex' }}
            alignItems="center"
            aria-label="Twitter"
            icon={
              <Image src="/assets/telegram.png" alt="Telegram" boxSize={6} />
            }
            variant="link"
            size="sm"
            ml={{ base: 0, md: '0.5rem' }}
          />
          <ELink
            as={Text}
            href="https://t.me/tokenautai"
            target="_blank"
            rel="noopener noreferrer"
            display={{ base: 'flex', md: 'none' }}
            alignItems="center"
          >
            Telegram
          </ELink>
          <ELink
            as={IconButton}
            bg="transparent"
            onClick={() =>
              window.open('https://twitter.com/TokenautAI', '_blank')
            }
            target="_blank"
            rel="noopener noreferrer"
            display={{ base: 'none', md: 'flex' }}
            alignItems="center"
            aria-label="Twitter"
            icon={<Image src="/assets/twitter.png" alt="Twitter" boxSize={6} />}
            size="sm"
            mr={{ base: 0, md: '0.5rem' }}
          />
          <ELink
            as={Text}
            href="https://twitter.com/TokenautAI"
            target="_blank"
            rel="noopener noreferrer"
            display={{ base: 'flex', md: 'none' }}
            alignItems="center"
            mt={{ base: '1rem', md: 0 }}
          >
            Twitter
          </ELink>
          <MenuItems to="/">Home</MenuItems>
          <MenuItems to="/news">News</MenuItems>
          {/* <MenuItems to="/rewards">Rewards</MenuItems> */}
          <MenuItems
            to="https://tokenaut.gitbook.io/whitepaper"
            target="_blank"
            rel="noopener noreferrer"
          >
            Whitepaper
          </MenuItems>
        </Box>

        <Box
          display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
          mt={{ base: '1rem', md: 0 }}
        >
          <Button
            as="a"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdgOi9i0Sb1WXK56TVXvhFF72QyjfEkii8saMmSspSD8E83WA/viewform?usp=sf_link"
            bg="transparent"
            isExternal
            border="1px"
            target="_blank"
          >
            Waitlist
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Navigation;
